import styled from 'styled-components';
import { motion } from 'framer-motion';

const Resume = () => {
  return (
    <ResumeStyled id="resume">
      <div className="resume-container">
        <embed
          src={`${process.env.PUBLIC_URL}/images/resume.pdf`}
          type="application/pdf"
          width="100%"
          height="900px"
        />
      </div>
    </ResumeStyled>
  );
};

const ResumeStyled = styled(motion.section)`
  width: 90%;
  margin: auto;
`;

export default Resume;
