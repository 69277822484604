export const webDevProjects = [
  {
    id: 1,
    url: "https://main.d2dvl9mudwuexj.amplifyapp.com/",
  },  
   
    {
      id: 2,
      url: "https://mixellence.netlify.app/",
    },  
    {
      id: 3,
      url: "https://urban-rhythms.netlify.app/",
    },
    {
      id: 4,
      url: "https://mysterious-forest.netlify.app/",
    },
    {
      id: 5,
      url: "https://la-nutrition.netlify.app/",
    },
    {
      id: 6,
      url: "https://play-rock-paper-scissors-react.netlify.app/",
    },

    {
      id: 7,
      url: "https://we-live-healthy.netlify.app/",
    },
    {
      id: 8,
      url: "https://weathervuejs.netlify.app/",
    },
    {
      id: 9,
      url: "https://tei-artworks.netlify.app/"
    },
    {
      id: 10,
      url: "https://fylo-react-8-16.netlify.app/"
    }
  ];


  